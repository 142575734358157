<template>
  <h1 class="page-title Biotif-bold">Perks</h1>
  <div class="mobile_view mb-4 mt-4">
    <div class="flex bg-white shadow-lg rounded-xl p-4 mb-4">
      <LightBulbIcon style="color: #f1df43" class="w-6" />
      <span class="text-desc ml-3">How to use Perks?</span>
      <button
        @click="openPerksUseModal"
        class="ml-auto font-bold"
        style="color: #049a59"
      >
        Read
      </button>
    </div>
    <div class="flex bg-white shadow-lg rounded-xl p-4">
      <TicketIcon style="color: #f1df43" class="w-6" />
      <span class="text-desc ml-3">Show your subscription card</span>
      <button
        @click="openPerksSubscriptionModal"
        class="ml-auto font-bold"
        style="color: #049a59"
      >
        Open
      </button>
    </div>
  </div>

  <div
    class="relative lg:w-1/2 lg:mb-0 mb-4 wage_wrapper-select2"
    style="border-radius: 100px; position: relative"
  >
    <input
      v-model="filter_name"
      placeholder="Search for brands..."
      class="
        wage_search_field
        w-full
        h-10
        rounded-full
        p-2
        focus:border-gray-400
      "
    />
    <button
      @click="set_clear"
      class="p-2 pt-2 ml-2 w-9 rounded-2xl text-center btn-cancel"
    >
      <img
        v-if="filter_name !== ''"
        class="w-4"
        :src="require('../../assets/images/clear.png')"
      />
      <img
        v-if="filter_name === ''"
        class="w-4"
        :src="require('../../assets/images/search.png')"
      />
    </button>
    <!-- <button  @click="set_filter" class="p-2  rounded-2xl w-9 text-center btn-search"><img class="w-8" :src="require('../../assets/images/search.png')" /></button> -->
  </div>
  <div class="lg:grid lg:grid-cols-3 gap-8 mt-8">
    <div class="lg:grid lg:col-span-2">
      <div class="lg:grid lg:grid-cols-3 gap-8">
        <div
          v-for="perk in page_data"
          :key="perk"
          class="mt-4 lg:mt-0 bg-white shadow-lg rounded-3xl p-4"
        >
          <img class="lg:w-full m-auto text-right h-16" :src="perk.logo" />
          <p
            class="
              Biotif-regular
              font-bold
              text-gray-900 text-center text-lg
              mt-2
            "
          >
            {{ perk.discount }}
          </p>
          <p class="text-center text-sm" style="color: #a3a6cc">
            {{ perk.text }}
          </p>
        </div>
      </div>
      <div class="pagination_div w-full pb-10 pt-4">
        <v-pagination
          v-model="page"
          :pages="Math.ceil(this.perks.length / 9)"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="updatePagination"
        />
      </div>
    </div>
    <div class="grid lg:grid-span-1 desk_view">
      <div
        class="
          relative
          lg:mt-2
          mt-8
          px-1
          py-4
          xl:py-4
          bg-white
          shadow-lg
          rounded-3xl
        "
      >
        <CardWithIcon iconName="InformationCircleIcon" iconPosition="center">
          <h2
            class="
              text-center text-gray-900 text-3xl
              mb-6
              Biotif-bold
              text-header
            "
          >
            How to use Perks?
          </h2>
          <div class="mb-8">
            <h3 class="font-bold mb-2" style="color: #111827">
              Select your discount
            </h3>
            <p class="text-gray-500 mb-5 text-sm">
              Find the discount you wish to use - search for brands or deal
              names, for example “Costa” or “coffee”.
            </p>
            <h3 class="font-bold mb-2" style="color: #111827">Say SHIFT Pro</h3>
            <p class="text-gray-500 text-sm">
              Mention SHIFT Pro perks to the customer service and show them your
              valid subscription card:
            </p>
          </div>
          <div
            :class="{
              subscription_active_image_div:
                user_detail.sub_status === 'active',
              subscription_inactive_image_div:
                user_detail.sub_status !== 'active',
            }"
            class="lg:p-8 lg:pt-16 pt-16 p-4 pb-8 relative"
          >
            <img
              v-if="user_detail.sub_status === 'active'"
              class="w-24 text-right absolute top-4 right-4"
              :src="require('../../assets/sp-logo-w.png')"
            />
            <img
              v-if="user_detail.sub_status !== 'active'"
              class="w-24 text-right absolute top-4 right-4"
              :src="require('../../assets/sp-logo.png')"
            />
            <span
              :class="{
                'text-white': user_detail.sub_status === 'active',
                'text-blue-900': user_detail.sub_status !== 'active',
              }"
              class="biotif-regular font-bold text-xl"
              >{{ user_detail.username }}</span
            >
            <p
              :class="{
                'text-white': user_detail.sub_status === 'active',
                'text-blue-900': user_detail.sub_status !== 'active',
              }"
              class="text-sm font-semibold"
            >
              Employer subscription
            </p>

            <div
              v-if="user_detail.sub_status === 'active'"
              class="grid grid-cols-2 mt-4"
            >
              <div>
                <p class="text-sm text-white-900">SUBSCRIPTION TYPE:</p>
                <p class="text-white text-xs">
                  {{ user_detail.sp_plan_name }}
                </p>
              </div>
              <div class="ml-auto">
                <p class="text-sm text-white-800">Next PAYMENT:</p>
                <p class="text-white text-xs">
                  {{ user_detail.sub_expires }}
                </p>
              </div>
            </div>
            <div v-if="user_detail.sub_status !== 'active'" class="mt-10">
              <p class="text-sm text-blue-900">SUBSCRIPTION INACTIVE</p>
            </div>
          </div>
        </CardWithIcon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardWithIcon from "@common/CardWithIcon";
import VPagination from "@hennge/vue3-pagination";

import { LightBulbIcon, TicketIcon } from "@heroicons/vue/solid";
import { APIdoGetPerks } from "@/api/common/api";

export default {
  components: {
    CardWithIcon,
    VPagination,
    LightBulbIcon,
    TicketIcon,
  },
  data: () => ({
    perks: [],
    page: 1,
    page_data: [],
  }),
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
    }),
  },
  mounted() {
    APIdoGetPerks().then((res) => {
      console.log("res", res);
      this.perks = res.data.results;
      this.updatePagination();
    });
  },
  watch: {},
  methods: {
    ...mapActions({
      // getShifts: 'candidate/getShifts',
      storeShiftKey: "candidate/storeShiftKey",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      doGetUserData: "authentication/doGetUserData",
    }),
    updatePagination() {
      this.page_data = [];
      let a =
        this.perks.length < this.page * 9 ? this.perks.length : this.page * 9;
      for (let i = (this.page - 1) * 9; i < a; i++) {
        this.page_data.push(this.perks[i]);
      }
    },

    openPerksUseModal() {
      const data = {
        isOpen: true,
        iconName: "InformationCircleIcon",
        componentName: "PerksEmployerModal",
        title: "",
        data: { modal_type: "small", flag: "howtouse" },
      };
      this.fetchtSetModalConfigs(data);
    },
    openPerksSubscriptionModal() {
      const data = {
        isOpen: true,
        iconName: "TicketIcon",
        componentName: "PerksEmployerModal",
        title: "",
        data: { modal_type: "small", flag: "sub" },
      };
      this.fetchtSetModalConfigs(data);
    },
  },
};
</script>
